import { definePlugin } from 'villus'

function cookies() {
  return definePlugin(function cookiesPlugin(context) {
    const { opContext } = context
    opContext.credentials = 'include'
  })
}

export { cookies }
