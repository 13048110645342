<template>
  <component
    :href="href"
    :to="to"
    :is="tagName"
    :class="`${color} ${size} rounded-md cursor-pointer inline-block w-auto whitespace-nowrap overflow-hidden max-w-full text-ellipsis text-center font-sans`"
    :disabled="disabled"
    @click="emit('click', $event)"
  >
    <slot />
  </component>
</template>

<style lang="postcss" scoped>
  .green {
    @apply bg-green/80 hover:bg-green active:bg-green/90 text-white;
  }

  .beige {
    @apply bg-beige/40 hover:bg-beige active:bg-beige/80;
  }

  .blue {
    @apply bg-blue/80 hover:bg-blue active:bg-blue/90 text-white font-bold;
  }

  .sm {
    @apply py-1 px-2 text-xs font-normal;
  }

  .md {
    @apply py-2 px-4 text-sm;
  }

  .lg {
    @apply py-3 px-6 font-montserrat uppercase;
  }

  button:disabled {
    @apply bg-neutral-200 text-neutral-400 hover:bg-neutral-200 active:bg-neutral-200 cursor-not-allowed;
  }

  .router-link-active {
    @apply !bg-blue/80 hover:!bg-blue active:!bg-blue/90 !text-white font-bold;
  }
</style>

<script setup lang="ts">
  import { ref } from 'vue'
  const emit = defineEmits(['click'])

  const props = defineProps({
    color: {
      type: String,
      default: 'beige',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    to: {
      type: Object,
      required: false,
    },

    passive: {
      type: Boolean,
      default: () => false,
    },

    href: {
      type: String,
      required: false,
    },

    size: {
      type: String,
      default: 'md',
    },
  })

  const tagName = ref('button')

  if (props.href) {
    tagName.value = 'a'
  }

  if (props.to) {
    tagName.value = 'router-link'
  }

  if (props.passive) {
    tagName.value = 'span'
  }
</script>
