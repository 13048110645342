function fullName(person) {
  const parts = [
    person.firstName || person.initials,
    person.middleName,
    person.lastName,
  ]
  return parts.filter((part) => part && part.length > 0).join(' ')
}

function sortName(person) {
  var fullName = person.lastName
  fullName += `, ${person.firstName || person.initials || ''}`
  if (person.middleName) {
    fullName += ` ${person.middleName}`
  }
  return fullName.trim()
}

function age(person) {
  if (!person.dateOfBirth) {
    return null
  }

  const dateOfBirth = new Date(person.dateOfBirth)
  const ageDifMs = Date.now() - dateOfBirth.getTime()
  const ageDate = new Date(ageDifMs)

  return Math.abs(ageDate.getUTCFullYear() - 1970)
}

function activeRoles(person) {
  return person.roles.filter((role) => role.active)
}

function hasRole(person, title) {
  return hasRoles(person, [title])
}

function hasRoles(person, titles) {
  for (const title of titles) {
    if (
      activeRoles(person)
        .map((role) => role.title)
        .indexOf(title) >= 0
    ) {
      return true
    }
  }

  return false
}

function hasAnyRoles(person) {
  return activeRoles(person).length > 0
}

function activeMemberships(person) {
  return person.memberships.filter((m) => m.active).map((m) => m.title)
}

function hasMemberships(person, titles) {
  const orgs = person.memberships.filter((m) => m.active).map((m) => m.organization)

  for (const org of orgs) {
    if (titles.indexOf(org) >= 0) {
      return true
    }
  }

  return false
}

export {
  activeMemberships,
  activeRoles,
  hasRole,
  hasRoles,
  hasAnyRoles,
  hasMemberships,
  fullName,
  sortName,
  age,
}
