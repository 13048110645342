import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('../components/Dashboard.vue'),
  },
  {
    path: '/penningmeester/jaarrekening/:year',
    name: 'year_statement',
    component: () => import('../components/treasury/YearStatement.vue'),
    props: true,
  },
  {
    path: '/afdeling',
    redirect: '/afdeling/dashboard',
  },
  {
    path: '/afdeling/dashboard',
    name: 'department_dashboard',
    component: () => import('../components/department/Dashboard.vue'),
  },
  {
    path: '/afdeling/jaaroverzicht/:year',
    name: 'year_mutations',
    component: () => import('../components/department/YearMutations.vue'),
  },
  {
    path: '/afdeling/leden',
    name: 'people',
    component: () => import('../components/people/List.vue'),
  },
  {
    path: '/afdeling/leden/:navision_id',
    name: 'person',
    component: () => import('../components/people/Details.vue'),
  },
  {
    path: '/afdeling/leden/:navision_id/hrm',
    name: 'hrm_form',
    component: () => import('../components/career/HrmTool.vue'),
  },
  {
    path: '/sollicitaties',
    name: 'candidacies',
    component: () => import('../components/career/Candidacies.vue'),
    children: [
      {
        path: '/sollicitaties/:uuid/beoordeel',
        name: 'review_candidacy',
        component: () => import('../components/career/ReviewCandidacy.vue'),
      },
    ],
  },
  {
    path: '/sollicitaties/:uuid',
    name: 'candidacy',
    component: () => import('../components/career/Candidacy.vue'),
  },
  {
    path: '/campagne',
    redirect: '/campagne/kaart',
  },
  {
    path: '/campagne/kaart',
    name: 'map',
    component: () => import('../components/campaign/Map.vue'),
  },
  {
    path: '/campagne/analyse',
    name: 'campaign_dashboard',
    component: () => import('../components/campaign/Dashboard.vue'),
  },
  {
    path: '/campagne/uitslagen',
    name: 'results',
    component: () => import('../components/campaign/Results.vue'),
  },
  {
    path: '/bibliotheek',
    name: 'library',
    component: () => import('../components/communication/Library.vue'),
  },
  {
    path: '/appel',
    redirect: '/appel/overzicht',
  },
  {
    path: '/appel/overzicht',
    name: 'posts',
    component: () => import('../components/communication/Posts.vue'),
  },
  {
    path: '/appel/beheer',
    name: 'posts_admin',
    component: () => import('../components/communication/Admin.vue'),
    children: [
      {
        path: '/appel/beheer/nieuw',
        name: 'add_post',
        component: () => import('../components/communication/NewPost.vue'),
      },
      {
        path: '/appel/beheer/labels',
        name: 'tags',
        component: () => import('../components/communication/Tags.vue'),
      },
      {
        path: '/appel/beheer/:uuid',
        name: 'edit_post',
        component: () => import('../components/communication/EditPost.vue'),
      },
    ],
  },
  {
    path: '/mindmapp',
    name: 'mindmapp',
    component: () => import('../components/mindmapp/NewSheet.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.name == 'people' && from.name == 'person') {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

export default router
