<template>
  <main :class="$route.name">
    <div v-if="!isDone" class="py-5 text-center">
      <loading-view :pill="true" />
    </div>

    <div v-else-if="error" class="px-4">
      <div
        class="relative mx-auto my-24 max-w-sm space-y-2 rounded-lg bg-white px-4 py-4 leading-6 shadow-xl"
      >
        <h2>
          <span
            class="inline-block bg-blue px-2 py-1 font-montserrat uppercase text-white"
            >Helaas</span
          >
        </h2>

        <p>Er is iets misgegaan met het ophalen van je gegevens.</p>
        <p>
          We zijn op de hoogte gesteld, en zullen het probleem zo spoedig
          mogelijk verhelpen.
        </p>
        <p>Excuses voor de overlast.</p>
      </div>
    </div>

    <div class="wrapper" v-else>
      <Header :me="data.me" :settings="data.settings" @logout="logout" />

      <section class="mx-auto max-w-5xl p-4 pb-8 pt-24">
        <router-view
          v-slot="{ Component }"
          :me="data.me"
          :is-development="isDevelopment"
          :settings="data.settings"
          :party-names="partyNames"
          @flash="emit('set:flash', $event)"
          @set:flash="emit('set:flash', $event)"
        >
          <keep-alive include="people">
            <component :is="Component" />
          </keep-alive>
        </router-view>
      </section>
    </div>
  </main>
</template>

<script setup lang="ts">
  import * as Sentry from '@sentry/vue'
  import { watch } from 'vue'
  import { useQuery } from 'villus'
  import query from '@/graphql/Me.graphql'

  import LoadingView from './LoadingView.vue'
  import Header from '@/components/Header.vue'

  const emit = defineEmits(['logout', 'set:flash'])
  const { data, error, isDone } = useQuery({ query })

  const props = defineProps<{
    isDevelopment: Boolean
    sessionId?: string | null
  }>()

  const partyNames = {
    'Christen Democratisch Appèl': {
      color: '#00894b',
      abbreviation: 'CDA',
    },
    'Volkspartij voor Vrijheid en Democratie': {
      color: '#455493',
      abbreviation: 'VVD',
    },
    'Partij voor de Vrijheid': {
      color: '#02b9ff',
      abbreviation: 'PVV',
    },
    'Democraten 66': {
      color: '#4bab2d',
      abbreviation: 'D66',
    },
    GroenLinks: {
      color: '#006b39',
      abbreviation: 'GroenLinks',
    },
    'Socialistische Partij': {
      color: '#c73d77',
      abbreviation: 'SP',
    },
    'Partij van de Arbeid': {
      color: '#9a0d1b',
      abbreviation: 'PvdA',
    },
    ChristenUnie: {
      color: '#0192b2',
      abbreviation: 'ChristenUnie',
    },
    'Partij voor de Dieren': {
      color: '#ebc308',
      abbreviation: 'PvdD',
    },
    '50Plus': {
      color: '#c2791d',
      abbreviation: '50+',
    },
    'Staatkundig Gereformeerde Partij': {
      color: '#7f8084',
      abbreviation: 'SGP',
    },
    'Politieke Beweging DENK': {
      color: '#40bac1',
      abbreviation: 'DENK',
    },
    'Forum voor Democratie': {
      color: '#6e0c14',
      abbreviation: 'FVD',
    },
    'Fryske Nasjonale Partij': {
      color: '#1A3B78',
      abbreviation: 'FNP',
    },
  }

  const logout = () => emit('logout')

  Sentry.addBreadcrumb({
    type: 'session-id',
    message: String(props.sessionId),
  })

  watch(
    () => data.value,
    (data) => {
      if (data?.me) {
        const sentryUser = {
          id: data.me.navisionId,
        } as SentryUser

        if (data.me.email) {
          sentryUser.email = data.me.email
        }

        Sentry.setUser(sentryUser)
      } else if (error.value) {
        Sentry.addBreadcrumb({
          type: 'graphql-result',
          data: error.value,
        })

        Sentry.captureException(new Error('Geen lidgegevens gevonden'))
      }
    },
  )
</script>

<style lang="scss" scoped>
  section:deep(h1) {
    @apply mb-4 font-montserrat uppercase;
  }

  section:deep(h1 span) {
    @apply inline-block bg-green px-2 py-0 text-lg text-white;
  }

  section:deep(h2) {
    @apply text-base;
  }
</style>
