<template>
  <div
    class="inline-flex items-center gap-2"
    :class="{ 'rounded-lg bg-blue px-3 py-2 text-white': pill }"
  >
    <arrow-path-icon class="size-4 animate-spin" />
    <span v-if="showText || pill">Laden…</span>
  </div>
</template>

<script setup lang="ts">
  import { ArrowPathIcon } from '@heroicons/vue/20/solid'
  import { ref } from 'vue'

  const showText = ref(false)
  defineProps<{ pill?: boolean }>()

  setTimeout(() => (showText.value = true), 1500)
</script>
