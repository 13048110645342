<template>
  <div
    class="fixed right-0 bottom-0 z-30 p-4 w-full md:w-auto transition-opacity"
    :class="{ 'opacity-0': hidden }"
  >
    <span
      class="px-4 bg-blue/90 text-white leading-10 shadow-xl rounded-md flex gap-2 text-sm cursor-pointer hover:bg-blue active:bg-blue/95"
      :class="{
        'bg-red-700 hover:bg-red-800 active:bg-red-700': flash.kind === 'alert',
      }"
      @click="dismiss"
    >
      <exclamation-triangle-icon v-if="flash.kind === 'alert'" class="w-4" />
      <information-circle-icon v-else class="w-4" />
      <span>{{ flash.message }}</span>
    </span>
  </div>
</template>

<script setup>
  import { ref } from 'vue'
  import {
    ExclamationTriangleIcon,
    InformationCircleIcon,
  } from '@heroicons/vue/24/solid'

  defineProps({
    flash: Object,
  })

  const emit = defineEmits(['dismissed'])
  const hidden = ref(true)

  const dismiss = () => {
    hidden.value = true
    setTimeout(() => emit('dismissed'), 150)
  }

  setTimeout(() => (hidden.value = false), 50)
  setTimeout(dismiss, 3500)
</script>
